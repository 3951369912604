export default {
	servicePath: 'resources',
	modelName: 'Resource',
	setupInstance(data, { models, defineSingleAssociation, defineManyAssociation }) {
		defineManyAssociation(data, 'relatedActivities', models.api.Activity);
		defineSingleAssociation(data, 'pdf', models.api.Pdf);
		return data;
	},
	state: {
		focuses: [
			{ label: 'Cognition', color: '#D27CBE' },
			{ label: 'Communication', color: '#E7CA5C' },
			{ label: 'Swallowing', color: '#9B99EE' },
			{ label: 'Virtual Rehab Center', color: '#95C5C7' }
		],
		types: ['Clinical Guide', 'Patient Education', 'Strategies', 'Visual Support', 'Skill Builder']
	},
	getters: {
		getFocusColor() {
			return (label) => {
				const focus = this.focuses.find((focus) => focus.label == label);
				return focus ? focus.color : undefined;
			};
		},
		isValidFocus() {
			return (label) => {
				return this.focuses.findIndex((focus) => focus.label == label) > -1;
			};
		}
	}
};
