import { createRouter, createWebHistory } from 'vue-router';
import { isEmpty } from 'lodash';
import qs from 'qs';
import { getServiceStore } from '@/plugins/FeathersAPI';
// import Home from '../views/Home.vue';
import middlewarePipeline from './middlewarePipeline';
import isAuthenticated from './middleware/isAuthenticated';
import requiresPayment from './middleware/requiresPayment';
import checkPermissions from '@/plugins/Admin/middleware/hasPermissions';
import * as observability from '@/observability';
import * as productfruits from '@/productfruits';
import { STEPS, createScopedStepName } from '../observability';

const routes = [
	// {
	// 	path: '/',
	// 	name: 'Home',
	// 	component: Home,
	// 	meta: {
	// 		middleware: []
	// 	}
	// },
	// {
	// 	path: '/about',
	// 	name: 'About',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
	// },
	{
		path: '/homework',
		name: 'Homework',
		component: () => import('../views/homeUser/Homework.vue'),
		meta: {
			middleware: [isAuthenticated, checkPermissions],
			permissions: () => ['patient', 'patient-deactivated']
		}
	},
	{
		path: '/history',
		name: 'History',
		component: () => import('../views/homeUser/History.vue'),
		meta: {
			middleware: [isAuthenticated, checkPermissions],
			permissions: () => ['patient', 'patient-deactivated']
		}
	},
	{
		path: '/payment',
		name: 'Payment',
		component: () => import('../views/Payment.vue'),
		meta: {
			middleware: [isAuthenticated, requiresPayment]
		}
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: () => import('../views/Dashboard.vue'),
		meta: {
			middleware: [isAuthenticated, checkPermissions],
			permissions: () => ['clinician']
		}
	},
	{
		path: '/patients',
		component: () => import('../views/EmptyParent.vue'),
		children: [
			{
				path: '',
				name: 'Patients',
				component: () => import('../views/Patients.vue'),
				meta: {
					middleware: [isAuthenticated, checkPermissions],
					permissions: () => ['clinician']
				}
			},
			{
				path: ':profileId',
				name: 'Patient',
				component: () => import('../views/Patient.vue'),
				meta: {
					middleware: [isAuthenticated, checkPermissions],
					permissions: () => ['clinician']
				},
				children: [
					{
						path: '',
						alias: 'overview',
						name: 'PatientOverview',
						component: () => import('../views/PatientOverview.vue'),
						meta: {
							middleware: [isAuthenticated, checkPermissions],
							permissions: () => ['clinician']
						}
					},
					{
						path: 'profile',
						name: 'PatientProfile',
						component: () => import('../views/PatientProfile.vue'),
						meta: {
							middleware: [isAuthenticated, checkPermissions],
							permissions: () => ['clinician']
						}
					},
					{
						path: 'goals',
						name: 'GoalsProfile',
						component: () => import('../views/PatientGoals.vue'),
						meta: {
							middleware: [isAuthenticated, checkPermissions],
							permissions: () => ['clinician']
						}
					},
					{
						path: 'treatment-plan',
						name: 'PatientTreatmentPlan',
						component: () => import('../views/PatientTreatmentPlan.vue'),
						meta: {
							middleware: [isAuthenticated, checkPermissions],
							permissions: () => ['clinician']
						}
					},
					{
						path: 'resources',
						name: 'PatientResources',
						component: () => import('../views/PatientResources.vue'),
						meta: {
							middleware: [isAuthenticated, checkPermissions],
							permissions: () => ['clinician']
						}
					},
					{
						path: 'homework',
						name: 'PatientHomework',
						component: () => import('../views/PatientHomework.vue'),
						meta: {
							middleware: [isAuthenticated, checkPermissions],
							permissions: () => ['clinician']
						}
					},
					{
						path: 'history-reports',
						name: 'PatientHistoryReports',
						component: () => import('../views/PatientHistoryReports.vue'),
						meta: {
							middleware: [isAuthenticated, checkPermissions],
							permissions: () => ['clinician']
						}
					}
				]
			}
		]
	},
	{
		path: '/treatments',
		name: 'Treatments',
		component: () => import('@/views/Treatments.vue'),
		meta: {
			middleware: [isAuthenticated, checkPermissions],
			permissions: () => ['clinician']
		}
	},
	{
		path: '/resources',
		name: 'Resources',
		component: () => import('@/views/Resources.vue'),
		meta: {
			middleware: [isAuthenticated, checkPermissions],
			permissions: () => ['clinician']
		}
	},
	// TODO - move or delete page
	{
		path: '/select-therapy',
		name: 'SelectTherapy',
		component: () => import('@/views/SelectTherapy.vue'),
		meta: {
			middleware: [isAuthenticated]
			// not checking permissions as this page should be deleted
			// or moved to admin section
		}
	},
	{
		path: '/exercise/:activityName/:activitySessionId?',
		name: 'Exercise',
		component: () => import('@/views/Exercise.vue'),
		meta: {
			middleware: [isAuthenticated, checkPermissions],
			permissions: () => ['clinician', 'patient']
		}
	},
	{
		path: '/report/:activitySessionId',
		name: 'Report',
		component: () => import('@/views/Report.vue'),
		meta: {
			middleware: [isAuthenticated, checkPermissions],
			permissions: () => ['clinician', 'patient']
		},
		props: true
	},
	{
		path: '/account',
		name: 'Account',
		component: () => import('@/views/Account.vue'),
		meta: {
			middleware: [isAuthenticated, checkPermissions],
			permissions: () => ['clinician', 'patient', 'patient-deactivated']
		},
		children: [
			{
				path: '',
				alias: 'details',
				name: 'AccountProfile',
				component: () => import('@/views/AccountProfile.vue'),
				meta: {
					middleware: [isAuthenticated, checkPermissions],
					permissions: () => ['clinician', 'patient', 'patient-deactivated']
				}
			},
			{
				path: 'billing',
				name: 'AccountBilling',
				component: () => import('@/views/AccountBilling.vue'),
				meta: {
					middleware: [isAuthenticated, checkPermissions],
					permissions: () => ['clinician']
				}
			}
		]
	},
	{
		path: '/help',
		name: 'Help',
		component: () => import('@/views/Help.vue'),
		meta: {
			middleware: [isAuthenticated, checkPermissions],
			permissions: () => ['clinician', 'patient', 'patient-deactivated']
		}
	},
	{
		path: '/maintenance',
		name: 'Maintenance',
		component: () => import('@/views/Maintenance.vue')
	},
	{
		path: '/404',
		name: 'NotFound',
		component: () => import('@/views/404.vue')
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'CatchAll',
		beforeEnter(to, from, next) {
			// if internal navigation - redirect to login / dashboard
			if (from && from.name) {
				return next('/');
			}
			// if external navigation - redirect to 404
			next('/404');
		}
	}
];

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes,
	// set custom query resolver
	parseQuery(query) {
		return qs.parse(query);
	},
	stringifyQuery(query) {
		return qs.stringify(query, { encode: false });
	}
});

router.beforeEach(async (to, from, next) => {
	const maintenance = await getServiceStore('maintenance').afterConnection();
	const user = getServiceStore('auth').user;
	const redirect = maintenance.checkRoute(to, user);
	if (redirect) return next(redirect);

	if (!to.meta.middleware || isEmpty(to.meta.middleware)) {
		return next();
	}
	const middleware = to.meta.middleware;

	const context = { to, from, next };

	return middleware[0]({
		...context,
		next: middlewarePipeline(context, middleware, 1)
	});
});

router.afterEach(async (to) => {
	observability.setView(to.fullPath);
	productfruits.trackPageChanged();

	const { startPerformanceStep } = await getServiceStore('performance-measurements');
	if (to.name === 'Dashboard') {
		startPerformanceStep(STEPS.GO_TO_DASHBOARD);
	}
	if (to.name === 'Patients') {
		startPerformanceStep(STEPS.GO_TO_PATIENTS);
	}
	if (to.name === 'Treatments') {
		startPerformanceStep(STEPS.GO_TO_TREATMENTS);
	}
	if (to.name === 'Resources') {
		startPerformanceStep(STEPS.GO_TO_RESOURCES);
	}
	if (to.name === 'PatientOverview') {
		startPerformanceStep(STEPS.GO_TO_PATIENT_OVERVIEW);
	}
	if (to.name === 'PatientTreatmentPlan') {
		startPerformanceStep(STEPS.GO_TO_PATIENT_TREATMENT_PLAN);
	}
	if (to.name === 'PatientResources') {
		startPerformanceStep(STEPS.GO_TO_PATIENT_RESOURCES);
	}

	// When a user begins a treatment to do, our goal is to measure how long it takes
	// to show the treatment's tutorial. The first part of going to a treatment is
	// setting up the activity session. This happens by navigating to the Exercise
	// page twice in quick succession. On the first navigation, the page's query
	// string contains activitySessionId set to an empty string. On the second
	// navigation, activitySessionId is set to a non-empty value. To capture an
	// accurate measure of how long it takes to go to the treatment tutorial, let's
	// start measuring the step when the first navigation happens.
	if (to.name === 'Exercise') {
		const isStartOfTreatmentSetup = to.params.activitySessionId === '';
		// The treatment "repeating single syllable words" includes a unique screen
		// before the tutorial that requires user input. If we want to measure how long
		// it takes to go to this treatment type's tutorial, we'd have to start the step
		// in a different place.
		const excludeTreatmentType = to.params.activityName === 'repeating-single-syllable-words';
		if (isStartOfTreatmentSetup && !excludeTreatmentType) {
			startPerformanceStep(STEPS.GO_TO_TREATMENT_TUTORIAL);
			startPerformanceStep(createScopedStepName(STEPS.GO_TO_TREATMENT_TUTORIAL, to.params.activityName));
		}
	}
});

export default router;
